import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import { func, node, object, shape, string } from 'prop-types';
import IconPlusWhite from '../../../../components/IconPlusWhite/IconPlusWhite';


import styles from './BlockFeaturedCard.module.css';


const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, styles.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};


const BlockFeaturedCard = props => {
  const {
  blockId,
  className,
  rootClassName,
  mediaClassName,
  media,
  responsiveImageSizes,
  options,
  textClassName,
  ctaButtonClass,
  title,
  text,
  callToAction,
} = props;
  const classes = classNames(rootClassName || styles.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  return (
    <BlockContainer id={blockId} className={classes}>
      <div className={styles.featuredCard}>

        <div className={styles.featuredCardContainer}>
     
        <FieldMedia
              media={media}
              sizes={responsiveImageSizes}
              className={mediaClassName || styles.featuredCardImage}
              options={options}
            />

        <IconPlusWhite className={styles.icon}></IconPlusWhite>
      <div className={styles.linkContainer}>
{hasTextComponentFields ? (
             <div className={classNames(textClassName, styles.containerText)}>
          <Field data={title} options={options} />
              </div>
            ) : null}
          </div>

          
          </div>
          </div>
   
    </BlockContainer>
  );
};

BlockFeaturedCard.propTypes = {
  blockId: PropTypes.string.isRequired,
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  mediaClassName: PropTypes.string,
  media: object, 
  responsiveImageSizes: PropTypes.string,
  options: PropTypes.object,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,

};

BlockFeaturedCard.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  responsiveImageSizes: '(max-width: 767px) 100vw, 600px',
  options: null,
  media: null, 
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,

};

export default BlockFeaturedCard;