import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import { func, node, object, shape, string } from 'prop-types';
import IconPlusBlack from '../../../../components/IconPlusBlack/IconPlusBlack';
import IconPlusWhite from '../../../../components/IconPlusWhite/IconPlusWhite';


import styles from './FlipCard.module.css';


const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, styles.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

if (typeof window !== 'undefined') {
  const hiddenElements = document.querySelectorAll('.flipCard');
  hiddenElements.forEach((el) => observer.observe(el));
}

const FlipCard = props => {
  const {
  blockId,
  className,
  rootClassName,
  mediaClassName,
  media,
  responsiveImageSizes,
  options,
  textClassName,
  ctaButtonClass,
  title,
  text,
  callToAction,
} = props;
  const classes = classNames(rootClassName || styles.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  return (
    <BlockContainer id={blockId} className={classes}>
      <div className={styles.flipCard}>
        <div className={styles.flipCardInner}>
          <div className={styles.flipCardFront}>
     
            <FieldMedia
              media={media}
              sizes={responsiveImageSizes}
              className={mediaClassName || styles.flipCardImage}
              options={options}
            />
            <div className={styles.cardNumber}>01</div>
           
            <IconPlusBlack className={styles.icon}></IconPlusBlack>
          </div>
   
          <div className={styles.flipCardBack}>
            <div className={styles.flipCardBackImageContainer}>
            <div className={styles.backCardNumber}>02</div>
            <FieldMedia
              media={media}
              sizes={responsiveImageSizes}
              className={mediaClassName || styles.flipCardBackImage}
              options={options}
            />
            </div>
            <div className={styles.backContent}>

            {hasTextComponentFields ? (
             <div className={classNames(textClassName, styles.slidingText)}>
          <Field data={title} options={options}
                 />
              </div>
            ) : null}
              <div className={styles.buyNow}>

                <div className={styles.buyNowLink}>
                {hasTextComponentFields ? (
             <div className={classNames(textClassName, styles.SeeMore)}>
          <Field data={text} options={options}
                 />
              </div>
            ) : null}

              </div>
              </div>
              <IconPlusWhite className={styles.iconPlusWhite}></IconPlusWhite>
            </div>
          </div>
        </div>
      </div>
    </BlockContainer>
  );
};

FlipCard.propTypes = {
  blockId: PropTypes.string.isRequired,
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  mediaClassName: PropTypes.string,
  media: object, 
  responsiveImageSizes: PropTypes.string,
  options: PropTypes.object,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,

};

FlipCard.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  responsiveImageSizes: '(max-width: 767px) 100vw, 600px',
  options: null,
  media: null, 
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,

};

export default FlipCard;