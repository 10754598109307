import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconPluswhiteIcon from '../../assets/icons8-plus-50 (1).png'

import css from './IconPlusWhite.module.css';

const IconPlusWhite = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);
  
    return (
      <img
        className={classes}
        src={IconPluswhiteIcon}
        alt="Plus Icon"
        width="16"
        height="17"
      />
    );
  };
  
  IconPlusWhite.defaultProps = { rootClassName: null, className: null };
  
  const { string } = PropTypes;
  
  IconPlusWhite.propTypes = {
    rootClassName: string,
    className: string,
  };
  
  export default IconPlusWhite;