
import React from 'react';
import { useEffect, useState } from 'react';
import { IconSpinner, LayoutComposer } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';
import FooterContainer from '../FooterContainer/FooterContainer.js';
import { validProps } from './Field';
import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';
import css from './PageBuilder.module.css';
import { entries } from 'lodash';
import { contactUsFromApi } from '../../util/api';


// Simple contact form component
const AboutUsContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  // console.log("here is formdata from pagebuilder.js",formData)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      // ---------------- FOR LOCAL ----------------------
      // const response = await fetch('http://localhost:3500/api/contact', { // Updated the URL with the correct port
      
      // ---------------- FOR DEPLOYMENT ----------------------
      // const response = await fetch('https://namename.store/api/contact', { // Updated the URL with the correct port
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(formData)
      // });
      const response = await contactUsFromApi(formData);
      setResponseMessage(response);
  
      // if (response.ok) {
      //   setResponseMessage('Your message has been sent successfully.');
      // } else {
      //   setResponseMessage('There was a problem sending your message.');
      // }
    } catch (error) {
      setResponseMessage('There was a problem sending your message.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
  <div className={css.aboutUsContact}>
    <div className={css.aboutUsContactForm}>
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className={css.submitButton} disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
        {responseMessage && <p>{responseMessage}</p>}
      </form>
    </div>
    </div>
  );
};

// Simple returns form component
const ReturnsContactForm = () => (
  <div className={css.returnsContactForm}>
    <h2>Return form</h2>
    <form>
      <div>
        <label htmlFor="yourName">Your Name:</label>
        <input type="text" id="yourName" name="yourName" className={css.smallInput} />
      </div>
      <div>
        <label htmlFor="orderNumber">Order Number:</label>
        <input type="text" id="orderNumber" name="orderNumber" className={css.smallInput} />
      </div>
      <div>
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" />
      </div>
      <div>
        <label htmlFor="message">Reason for return:</label>
        <textarea id="message" name="message" className={css.smallTextarea}></textarea>
      </div>
      <button type="submit" className={css.submitButton}>Submit</button>
    </form>
  </div>
);

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;
  const title = validProps(pageTitle, fieldOptions)?.content;
  const description = validProps(pageDescription, fieldOptions)?.content;
  const openGraph = validProps(socialSharing, fieldOptions);
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

const PageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    currentPage,
    ...pageProps
  } = props;

  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }

  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);

  const layoutAreas = `
    topbar
    main
    footer
  `;
  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          // console.log(pageMetaProps.title)
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer currentPage={currentPage} />
              </Topbar>
              <Main as="main" className={css.main}>
                {sections.length === 0 && inProgress ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <SectionBuilder sections={sections} options={options} />
                    {pageMetaProps.title === "About us" && <AboutUsContactForm />}
                    {/* {pageMetaProps.title === "Returns" && <ReturnsContactForm />} */}
                  </>
                )}
              </Main>
              <Footer>
                <FooterContainer />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, StaticPage, SectionBuilder };
export default PageBuilder;